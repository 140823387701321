import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface T_SpeedShowCase {
  background: string;
  products: {
    name: string;
    backgroundImg: string;
    mobileBackgroundImg: string;
    download: {
      title: string;
      desc: string;
      speed: string;
      sub?: string;
    };
    upload: {
      title: string;
      desc: string;
      speed: string;
      sub?: string;
    };
  }[];
}

interface Props {
  data: T_SpeedShowCase;
}

export const SpeedShowCase = ({ data }: Props) => {
  const [index, setIndex] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (i: number) => {
    setIndex(i);
    setShowDropDown(false);
  };

  const dropPlaceHolderStyle = {
    borderRadius: showDropDown ? '24px 24px 0 0' : '24px',
  };

  const getArrowStyle = useCallback(width => {
    return {
      minWidth: '40px',
      minHeight: '40px',
      ...(width > 768 && {
        minWidth: '48px',
        minHeight: '48px',
      }),
    };
  }, []);

  const arrowStyle = useMemo(() => getArrowStyle(viewportWidth), [viewportWidth, getArrowStyle]);
  const isMobile = useMemo(() => viewportWidth < 768, [viewportWidth]);

  if (!data) return null;
  return (
    <div
      style={{
        height: 624,
        backgroundImage: `url(${
          isMobile ? data.products[index].mobileBackgroundImg : data.products[index].backgroundImg
        })`,
        padding: 25,
      }}
      className="text-white bg-cover bg-center relative">
      <div className="md:container m-auto ">
        <Tabs index={index} onChange={handleChange}>
          <div className="flex justify-center mb-4">
            {!isMobile ? (
              <TabList className="border-white rounded-full overflow-hidden flex border-2">
                {data.products.map((p, i) => {
                  let cls = cx('p-4 w-48 md:w-64 md:sky-h5-black sky-h5-black', {
                    'bg-white text-midnight': index === i,
                    'border-l-2 border-r-2 border-white': i % 2 !== 0,
                  });
                  return (
                    <Tab key={i} className={cls} style={{}}>
                      {p.name}
                    </Tab>
                  );
                })}
              </TabList>
            ) : (
              //Drop down for mobile
              <div className="relative" style={{ width: 325 }}>
                <button
                  type="button"
                  className="bg-white border-white py-2 text-midnight w-full flex justify-between items-center showcase-dropdown"
                  style={dropPlaceHolderStyle}
                  id="options-menu"
                  onClick={() => setShowDropDown(!showDropDown)}
                  aria-haspopup="true"
                  aria-expanded="true">
                  {data.products[index].name}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-11 w-11 absolute"
                      style={{ top: 10, right: 18 }}>
                      <path
                        fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${showDropDown ? 'flex' : 'hidden'} absolute shadow-lg z-10 showcase-dropdown-item`}
                  aria-labelledby="options-menu"
                  role="menu">
                  <div className="w-full" role="none">
                    {data.products
                      .map((p, i) => {
                        let cls = cx('block text-midnight py-3', {});
                        return (
                          <>
                            <button
                              key={i}
                              type="button"
                              className={cls}
                              onClick={() => handleChange(i)}
                              role="menuitem">
                              {p.name}
                            </button>
                            {i !== data.products.length - 1 && (index !== data.products.length - 1 || i === 0) && (
                              <div className="border"></div>
                            )}
                          </>
                        );
                      })
                      .filter((p, i) => i !== index)}
                  </div>
                </div>
              </div>
            )}
          </div>
          <TabPanels>
            {data.products.map((p, i) => {
              return (
                <TabPanel key={i}>
                  <div className="h-144 md:h-128 flex flex-col md:flex-row  justify-between items-center">
                    <div className="w-full md:w-1/3 flex flex-col items-center">
                      <div style={arrowStyle}>
                        <img src="/images/arrow.svg" alt="arrow" />
                      </div>
                      <p className="xl:hidden text-bborange sky-h2-reg font-bold">{p.download.title}</p>
                      <p className="hidden xl:flex text-bborange sky-h2-reg font-bold">{p.download.title}</p>
                      <p className="xl:hidden sky-h2-reg font-bold text-center">{p.download.desc}</p>
                      <p className="hidden xl:flex sky-h2-reg font-bold text-center">{p.download.desc}</p>
                      <p className="sky-h3-reg pt-4 md:pt-8">
                        {p.download.sub ? (
                          <span>
                            {p.download.sub} <strong>{p.download.speed}</strong>
                          </span>
                        ) : null}
                      </p>
                    </div>
                    <div className="w-full md:w-1/3 -my-12 md:my-0 flex-1  md:h-128"></div>
                    <div className="w-full md:w-1/3 flex flex-col items-center">
                      <div className="transform rotate-180" style={arrowStyle}>
                        <img src="/images/arrow.svg" alt="arrow" />
                      </div>
                      <p className="xl:hidden text-bborange sky-h2-reg font-bold ">{p.upload.title}</p>
                      <p className="hidden xl:flex text-bborange sky-h2-reg font-bold ">{p.upload.title}</p>
                      <p className="xl:hidden sky-h2-reg font-bold text-center">{p.upload.desc}</p>
                      <p className="hidden xl:flex sky-h2-reg font-bold text-center">{p.upload.desc}</p>
                      <p className="sky-h3-reg pt-4 md:pt-8">
                        {p.upload.sub ? (
                          <span>
                            {p.upload.sub} <strong>{p.upload.speed}</strong>
                          </span>
                        ) : null}
                      </p>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </div>
      <p className="sky-sub md:sky-sub pt-16 md:pt-0 md:pb-10 text-center">
        Based on average peak time speeds of{' '}
        {index === 0
          ? '916 Mbps download/520 Mbps upload . Estimates based on a 4GB movie file for download and 100 5.8MB holiday photos'
          : index === 1
          ? '313 Mbps download/108 Mbps upload . Estimates based on a 4GB movie file for download and 100 5.8MB holiday photos'
          : '50Mbps download/ 10Mbps upload'}
      </p>
    </div>
  );
};
